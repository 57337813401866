import React from 'react';
import FaqItem from '../IndexScreen/FaqItem';
import Footer from '../../layout/Footer';
import Section from "../../layout/Section"
import SectionHeader from "../../layout/SectionHeader"

export default function FAQScreen() {
  return (
    <>
      <Section>
        <SectionHeader>Frequently Asked Questions</SectionHeader>
        <FaqItem
          title="What is Splyt?"
          desc="Splyt helps group of people to pool together funds so they can pay together. It works like magic and eliminates the need for complex calculations or awkward conversations about who owes who what."
          expanded={false} />
        <FaqItem 
          title="Is Splyt really free?" 
          desc="Yes it’s really free to use Splyt there are no hidden fees!"
          expanded={false} />
        <FaqItem 
          title="What banks or payment methods are supported?" 
          desc="We support every major US bank and credit card."
          expanded={false} />
         <FaqItem 
          title="Do you run credit checks? Does it appear on my credit report?" 
          desc="We do not perform credit checks and do not report to the credit bureaus. Personal data that you provide us with is strictly only used to verify your identity as per Federal legal requirements."
          expanded={false} />
      </Section>
      <Footer />
    </>
  );
}
